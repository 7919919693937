import React from "react";
import "./App.css";

import Header from "./components/Header";
import Notable from "./components/Notable";
import Now from "./components/Now";
import Pass from "./components/Pass";

function App() {
  return (
    <div class="container">
      <Header />

      <div />

      <div class="columns">
      {/*  <div class="column col-3 col-md-12" style={{ padding: "2rem" }}>
          <div style={{ margin: "7rem 0" }}>
            <strong>PROJECTS</strong>
          </div>
          <Notable />
        </div>*/}
        <div class="column col-6 col-md-12 col-mx-auto" style={{ padding: "2rem" }}>
          <div style={{ margin: "7rem 0" }}>
            <strong>WORKS</strong>
          </div>
          <Pass />
        </div>
        <div class="column col-3 col-md-12 col-mx-auto" style={{ padding: "2rem" }}>
          <div style={{ margin: "7rem 0" }}>
            <strong>BIO</strong>
          </div>
          <Now />
        </div>
      </div>
    </div>
  );
}

export default App;
