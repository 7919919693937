import React from "react";

export default function Pass() {
  return (
    <div>
      <table class="table">
        <tbody>
          <tr>
            <td>Full stack software engineer at Seeing Machines - Canberra</td>
            <td>2023</td>
          </tr>
          <tr>
            <td>
              IT Officer at The Congress of Aboriginal and Torres Strait
              Islander Nurses and Midwives - Canberra
            </td>
            <td>2021</td>
          </tr>
          <tr>
            <td>
              Trading Signal{" "}
              <a href="https://overinvested.co" target="_blank">
                https://overinvested.co
              </a>{" "}
              (comming soon)
            </td>
            <td>2021</td>
          </tr>

          <tr>
            <td>
              Member of Australian Computer Society{" "}
              <a href="https://www.acs.org.au/" target="_blank">
                https://www.acs.org.au/
              </a>
            </td>
            <td>2020</td>
          </tr>
          <tr>
            <td>
              PTE Read Aloud Practice Platform{" "}
              <a href="https://engapp.vercel.app" target="_blank">
                https://engapp.vercel.app
              </a>{" "}
              (in constructing)
            </td>
            <td>2019</td>
          </tr>
          <tr>
            <td>Web developer at Naturaly Shop </td>
            <td>2019</td>
          </tr>
          <tr>
            <td>
              Bachelor of Information Technology at Southern Cross University -
              Sydney
            </td>
            <td>2019</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
