import React from "react";

export default function Now() {
  return (
    <div>
      <p>
        I am a coffee enthusiast and full-stack engineer with a strong passion
        to create products that would make an impact. I grew up in Vietnam and
        now I am based in Canberra, Australia
        <br /> <br />
        I enjoy extracting insight from data with my analytical and technical
        abilities. For example, I am working on building a trading strategy and
        writing a bot to automate the trades.
        <br /> <br />
        Also, I like to solve my problems with technology, that's why I started
        the English Read Aloud Project. A website simulating PTE Read aloud Exam
        testing experience to assist users to familiarise themselves with the
        test and improve by using score reports.
        <br /> <br />
      </p>
    </div>
  );
}
