import React from "react";

export default function Header() {
  return (
    <div style={{ marginTop: "1rem" }}>
      <header class="navbar">
        <section class="navbar-section"></section>
        <section class="navbar-center">
          <span>V.D Nguyen</span>
        </section>
        <section class="navbar-section"></section>
      </header>
    </div>
  );
}
